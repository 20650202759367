import React, {useState} from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import {Helmet} from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Nav from '../components/Nav';
import Footer from '../components/Footer';

import '../Assets/Styles/pages/Booking.css';

function Bookings() {

  const [isChecked, setIsChecked] = useState();
  const [conf, setConf] = useState(false);

  const [vMake, setVMake] = useState('');
  const [vModel, setVModel] = useState('');
  const [vIN, setVIN] = useState('');
  const [vYear, setVYear] = useState(new Date());

  const [fleetCount, setFleetCount] = useState('');
  const [fleetVTA, setFleetVTA] = useState('');
  const [fleetCName, setFleetCName] = useState('');
  const [fleetPO, setFleetPO] = useState('');
  const [fleetOtherInst, setFleetOtherInst] = useState('');
  const [fleetWPO, setFleetWPO] = useState('');
  const [fleetPayOption, setFleetPayOption] = useState('');
  const [fleetPST, setFleetPST] = useState('');
  const [fleetGST, setFleetGST] = useState('');

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [service, setService] = useState('');
  const [specificVehicle, setSpecificVehicle] = useState('');
  const [appoint_date, setAppointdate] = useState('');
  const [appointTime, setAppointTime] = useState('');
  const [appointLocation, setAppointlocation] = useState('');
  const [appointCity, setAppointCity] = useState('');
  const [appointState, setAppointState] = useState('');
  const [appointZipcode, setAppointZipcode] = useState('');
  const [concerns, setConcerns] = useState('');
  const [vehicleCount, setVehicleCount] = useState('');
  const [paymentMeans, setPaymentMeans] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [payConf, setPayConf] = useState('');
  const [allowPromo, setAllowPromo] = useState(false);

  const handleradioChange = (e) => {
    const { value } = e.target;

    setService(value);
    setIsChecked(true);
  };

  const handleradioChange2 = (e) => {
    const { value } = e.target;

    setService(value);
    setIsChecked(false);
  };

  const handlerad = (e) => {
    const { value } = e.target;

    setFleetWPO(value);
  }

  const handlerad2 = (e) => {
    const { value } = e.target;

    setFleetPayOption(value);
  }

  const handlerad3 = (e) => {
    const { value } = e.target;

    setFleetPST(value);
  }

  const handlerad4 = (e) => {
    const { value } = e.target;

    setFleetGST(value);
  }

  const handle = (e) => {
    const { value } = e.target;

    setSpecificVehicle(value);
  }

  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  async function fleet() {

    await axios({
      method: "post",
      headers: {
        "Content-Type": 'application/json',
        "Accept": "*"
      },
      url: "https://api.destivictsolutions.com/fleets",
      mode:'cors',
      data: {
        vehicle_count: fleetCount,
        vehicle_travel_areas: fleetVTA,
        contact_person: fleetCName,
        po_instructions: fleetPO,
        other_instructions: fleetOtherInst,
        purchase_order: fleetWPO,
        payment_option: fleetPayOption,
        pst_exemption: fleetPST,
        gst_exemption: fleetGST
      }
    })
    .then(res => console.log(res))
    .catch(error => console.log(error))
  }
  
  async function book() {
    try {
      const response = await axios.post("https://api.destivictsolutions.com/bookings", {
        firstname,
        lastname,
        phone,
        email,
        service_type: service,
        specific_vehicle: specificVehicle,
        appoint_date,
        appoint_time: appointTime,
        appoint_location: appointLocation,
        appoint_city: appointCity,
        appoint_state: appointState,    
        appoint_zipcode: appointZipcode,
        concerns,
        vehicle_count: vehicleCount,
        payment_means: paymentMeans,
        billing_address: billingAddress,
        pay_conf: payConf,
        allow_promo: allowPromo
      }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "*/*"
        }
      });
  
      console.log("Booking successful:", response.data);
  
      // ✅ Ensure vehicle() is awaited
      await vehicle();

      if (isChecked) {
        await fleet();
      }
  
      setConf(true);
      reset();
  
    } catch (error) {
      console.error("Booking error:", error.response?.data || error.message);
    }
  }

  async function vehicle() {
    try {
      const response = await axios.post("https://api.destivictsolutions.com/vehicles", {
        vehicle_year: vYear,
        vehicle_make: vMake,
        vehicle_model: vModel,
        vin: vIN
      }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "*/*"
        }
      });
  
      console.log("Vehicle added:", response.data);

    } catch (error) {
      console.error("Vehicle error:", error.response?.data || error.message);
    }
  }
  
  /*const addToCal = async () => {
    await axios({
      url: "https://www.googleapis.com/calendar/v3/calendars/destivictsolutions@gmail.com/events",
      method: "post",
      headers: {
        "Authorization": ""
      }
    })
  } */

  return (
    <div className='row' id='Booking'>
      <Helmet>
        <title>Bookings - Destivict</title>
      </Helmet>
        <header>
          <Nav/>
          <div id='hd-inner'>
            <h1>Book a Service</h1>
          </div>
        </header>
        <span style={{placeItems: 'center', display: 'grid'}}>
            <form onSubmit={handleSubmit(book)} method='post' style={{display: 'grid', textAlign: 'start'}}>
              <h2>Client Information</h2>
              <span id='formrw'>
                <span style={{display: 'grid'}}>
                  <label>First Name</label>
                  <input required type='text' onChange={e => setFirstname(e.target.value)}/>
                </span>
                <span style={{display: 'grid'}}>
                  <label>Last Name</label>
                  <input required type='text' onChange={e => setLastname(e.target.value)}/>
                </span>
              </span>
              <span id='formrw'>
                <span style={{display: 'grid'}}>
                  <label>Phone Number</label>
                  <input id='phone' required type='number' name='phone' pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onChange={e => setPhone(e.target.value)}/>
                </span>
                <span style={{display: 'grid'}}>
                  <label>Email</label>
                  <input required type='email' onChange={e => setEmail(e.target.value)}/>
                </span>
              </span>
              <span style={{display: 'grid', paddingTop: '4%'}}>
                <h2>Service Selection</h2>
                <p>Type of Service Required</p>
                {errors.radio ? <span style={{color: 'red'}}>Please select at least one Service type</span> : ""}
                <span id='bkchecksp'>
                  <span style={{display: 'flex'}}>
                    <input type="radio" id="bkcheck" name="serv_type" {...register("radio", { required: true})} onChange={handleradioChange2} value="Rust Proofing"/>
                    <label for="Rust Proofing">Rust Proofing</label>
                  </span>
                  <span style={{display: 'flex'}}>
                    <input type="radio" id="bkcheck" name="serv_type" {...register("radio", { required: true})} onChange={handleradioChange2} value="Undercoating"/>
                    <label for="Undercoating">Undercoating</label>
                  </span>
                  <span style={{display: 'flex'}}>
                    <input type="radio" id="bkcheck" name="serv_type" {...register("radio", { required: true})} onChange={handleradioChange2} value="Tire Change"/>
                    <label for="Tire Change">Tire Change</label>
                  </span>
                </span>
              </span>
              <span id='bkchecksp'>
                <span style={{display: 'flex'}}>
                    <input type="radio" id="bkcheck" name="serv_type" {...register("radio", { required: true})} onChange={handleradioChange2} value="Desalting Services"/>
                    <label for="Desalting Services">Desalting Services</label>
                </span>
                <span style={{display: 'flex'}}>
                    <input type="radio" id="bkcheck" name="serv_type" {...register("radio", { required: true})} onChange={handleradioChange2} value="Exterior Detailing"/>
                    <label for="Exterior Detailing">Exterior Detailing</label>
                </span>
                <span style={{display: 'flex'}}>
                    <input type="radio" id="bkcheck2" name="serv_type" value="Fleet Service" checked={isChecked} {...register("radio", { required: true})} onChange={handleradioChange}/>
                    <label for="Fleet Service">Fleet Service</label>
                </span>
              </span>

              {isChecked == true ?
                <>
                  <span style={{display: 'grid', paddingTop: '4%'}}>
                    <h2>Fleet Information</h2>
                    <span id='formrw'>
                      <span style={{display: 'grid'}}>
                        <label>Vehicle Count</label>
                        <input type='number' required onChange={e => setFleetCount(e.target.value)}/>
                      </span>
                      <span style={{display: 'grid'}}>
                        <label>Vehicle Travel Area(s)</label>
                        <input type='text' required onChange={e => setFleetVTA(e.target.value)}/>
                      </span>
                    </span>
                    <span id='formrw'>
                      <span style={{display: 'grid'}}>
                        <label>Contact Person's Name</label>
                        <input type='text' required onChange={e => setFleetCName(e.target.value)}/>
                      </span>
                      <span style={{display: 'grid'}}>
                        <label>P.O Instructions</label>
                        <input type='text' required onChange={e => setFleetPO(e.target.value)}/>
                      </span>
                    </span>
                    <span id='formrw'>
                      <span style={{display: 'grid'}}>
                        <label>Other Instructions</label>
                        <input type='text' onChange={e => setFleetOtherInst(e.target.value)}/>
                      </span>
                    </span>
                    <span id='bkchecksp'>
                      <span id='m1' style={{display: 'grid'}}>
                        <label style={{fontSize: 'unset'}}>Purchase Order </label>
                        {fleetWPO.length < 1 ? <span style={{color: 'red'}}>Do you want Purchase Order</span> : ""}
                        <span style={{display: 'flex'}}>
                          <input type="radio" name='fleet' id="bkcheck" required onChange={handlerad} value={false}/>                      
                          <label style={{fontSize: 'unset', marginRight: '5%'}} for="no">No</label>
                          <input type="radio" name='fleet' id="bkcheck" required onChange={handlerad} value={true}/>
                          <label style={{fontSize: 'unset', marginRight: '5%'}} for="yes">Yes</label>
                        </span>
                      </span>
                      <span id='m2' style={{display: 'grid'}}>
                        <label id='mv' style={{fontSize: 'unset'}}>Payment Option</label>
                        {fleetPayOption.length < 1 ? <span style={{color: 'red'}}>Select a Payment Option</span> : ""}
                        <span style={{display: 'flex'}}>
                          <input type="radio" name='fleet2' id="bkcheck" required onChange={handlerad2} value="Credit"/>                      
                          <label style={{marginRight: '5%'}} for="credit">Credit (Net 30)</label>
                          <input type="radio" name='fleet2' id="bkcheck" required onChange={handlerad2} value="ATT"/>
                          <label style={{marginRight: '5%'}} for="att">During Service</label>
                        </span>
                      </span>
                    </span>
                    <span id='bkchecksp'>
                      <span style={{display: 'grid', width: '48%'}}>
                        <label style={{fontSize: 'unset'}}>PST Exemption</label>
                        {fleetPST.length < 1 ? <span style={{color: 'red'}}>Select PST Exemption</span> : ""}
                        <span style={{display: 'flex'}}>
                          <input type="radio" name='fleet21' id="bkcheck" required onChange={handlerad3} value={false}/>                      
                          <label style={{fontSize: 'unset', marginRight: '5%'}} for="no">No</label>
                          <input type="radio" name='fleet21' id="bkcheck" required onChange={handlerad3} value={true}/>
                          <label style={{fontSize: 'unset', marginRight: '5%'}} for="yes">Yes</label>
                        </span>
                      </span>
                      <span style={{display: 'grid', width: '48%'}}>
                        <label style={{fontSize: 'unset'}}>GST Exemption</label>
                        {fleetGST.length < 1 ? <span style={{color: 'red'}}>Select GST Exemption</span> : ""}
                        <span style={{display: 'flex'}}>
                          <input type="radio" name='fleet22' id="bkcheck" required onChange={handlerad4} value={false}/>                      
                          <label style={{fontSize: 'unset', marginRight: '5%'}} for="no">No</label>
                          <input type="radio" name='fleet22' id="bkcheck" required onChange={handlerad4} value={true}/>
                          <label style={{fontSize: 'unset', marginRight: '5%'}} for="yes">Yes</label>
                        </span>
                      </span>
                    </span>
                  </span>
                </>
                : ""
              }
                <>
                  <span style={{display: 'grid', paddingTop: '4%'}}>
                    <p>Specific Vehicle or Equipment Type</p>
                    {specificVehicle.length == false ? <span style={{color: 'red'}}>Please select at least one Car type</span> : ""}
                    <span id='bkchecksp'>
                      <span style={{display: 'flex'}}>
                        <input type="radio" id="bkcheck" name="spec" onChange={handle} required value="Car"/>
                        <label for="Car">Car</label>
                      </span>
                      <span style={{display: 'flex'}}>
                        <input type="radio" id="bkcheck" name="spec" onChange={handle} required value="Truck"/>
                        <label for="Truck">Truck</label>
                      </span>
                      <span style={{display: 'flex'}}>
                        <input type="radio" id="bkcheck" name="spec" onChange={handle} required value="Industrial machinery"/>
                        <label for="Industrial machinery">Industrial machinery</label>
                      </span>
                    </span>
                  </span>
                  <span style={{display: 'grid', paddingTop: '4%'}}>
                    <h2>Vehicle Details</h2>
                    <span id='formrw'>
                      <span style={{display: 'grid'}}>
                        <label>Make</label>
                        <input type='text' required onChange={e => setVMake(e.target.value)}/>
                      </span>
                      <span style={{display: 'grid'}}>
                        <label>Model</label>
                        <input type='text' required onChange={e => setVModel(e.target.value)}/>
                      </span>
                    </span>
                    <span id='formrw'>
                      <span style={{display: 'grid'}}>
                        <label>Year</label>
                        <DatePicker
                          selected={vYear}
                          onChange={date => setVYear(date)}
                          showYearPicker
                          dateFormat="yyyy"
                          required
                        />
                      </span>
                      <span style={{display: 'grid'}}>
                        <label>VIN</label>
                        <input type='text' required onChange={e => setVIN(e.target.value)}/>
                      </span>
                    </span>
                  </span>
                  <span style={{display: 'grid', paddingTop: '4%'}}>
                    <h2>Appointment Details</h2>
                    <span id='formrw'>
                      <span style={{display: 'grid'}}>
                        <label>Date</label>
                        <input type='date' min={(new Date().getFullYear()) + "-" + ("0" + (new Date().getMonth() + 1 )) + "-" + new Date().getDate()} required onChange={e => setAppointdate(e.target.value)}/>
                      </span>
                      <span style={{display: 'grid'}}>
                        <label>Time</label>
                        <input type='time' required onChange={e => setAppointTime(e.target.value)}/>
                      </span>
                    </span>
                    <span id='formrw'>
                      <span style={{display: 'grid'}}>
                        <label>Address for Service Delivery</label>
                        <input type='text' required onChange={e => setAppointlocation(e.target.value)} placeholder='Street name and number'/>
                      </span>
                      <span style={{display: 'grid'}}>
                        <label>City</label>
                        <input type='text' required onChange={e => setAppointCity(e.target.value)}/>
                      </span>
                    </span>
                    <span id='formrw'>
                      <span style={{display: 'grid'}}>
                        <label>Province</label>
                        <input type='text' required onChange={e => setAppointState(e.target.value)}/>
                      </span>
                      <span style={{display: 'grid'}}>
                        <label>Zipcode</label>
                        <input type='text' required onChange={e => setAppointZipcode(e.target.value)}/>
                      </span>
                    </span>
                  </span>
                  <span style={{display: 'grid', paddingTop: '4%'}}>
                    <h2>Additional Requirement</h2>
                    <span id='formrw' style={{display: 'grid'}}>
                      <span style={{width: '100%', display: 'grid'}}>
                        <label>Special Requests or Concerns</label>
                        <textarea rows='5' cols='100' onChange={e => setConcerns(e.target.value)}></textarea>
                      </span>
                      <span style={{width: '100%', display: 'grid'}}>
                        <label>Fleet Size</label>
                        <input type='number' min="1" placeholder='How many cars?' required onChange={e => setVehicleCount(e.target.value)}/>
                      </span>
                    </span>
                  </span>
                  <span style={{display: 'grid', paddingTop: '4%'}}>
                    <h2>Payment Information</h2>
                    <span id='formrw'>
                      <span style={{display: 'grid'}}>
                        <label>Preferred Payment Method</label>
                        <select value={paymentMeans} onChange={(e) => setPaymentMeans(e.target.value)} required>
                          <option></option>
                          <option value="Bank desposit">Bank Deposit</option>
                          <option value="E-transfer">Interac or E-transfer</option>
                          <option value="POS">POS</option>                          
                        </select>
                      </span>
                      <span style={{display: 'grid'}}>
                        <label>Billing Address</label>
                        <input type='text' required onChange={e => setBillingAddress(e.target.value)}/>
                      </span>
                    </span>
                  </span>
                  <span id='mlast' style={{display: 'grid', paddingTop: '4%'}}>
                    <h2>Confirmation Preference</h2>
                    <span id='formrw'>
                      <span style={{display: 'grid'}}>
                        <label>Preferred Means</label>
                        <input type='text' required onChange={e => setPayConf(e.target.value)}/>
                      </span>
                      <span style={{display: 'grid'}}>
                        <label>Consent for Promotions or Follow-Up</label>
                        <select value={allowPromo} onChange={(e) => setAllowPromo(e.target.value)} required>
                          <option></option>
                          <option value="true">True</option>
                          <option value="false">False</option>
                        </select>
                      </span>
                    </span>
                  </span>
                  <button className="btn">Submit</button>
                  {conf && <span style={{color: "blue", fontSize: '20px', paddingTop: "20px"}}>You've successfully booked our service.</span>}
                </>
            </form>
        </span>
        <Footer/>
    </div>
  )
}

export default Bookings;