import React from 'react';
import {Helmet} from "react-helmet";

import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Getsec from '../components/Getsec';

import ceo from '../Assets/Images/CEO.jpg'

import '../Assets/Styles/pages/About.css';

function About() {
  return (
    <div className='row' id='About'>
      <Helmet>
        <title>About Us - Destivict</title>
      </Helmet>
        <header>
          <Nav/>
          <div id='hd-inner' style={{backgroundColor: "white", borderRadius: "10px", padding: "3%"}}>
            <h1>About us</h1>
            <div id='abtus'>
                <span id='cc1'>At DESTIVICT SOLUTIONS INC, we are committed to providing high-quality
                  rust protection services that safeguard our clients' vehicles and industrial
                  equipment from costly rust damage.<br/>
                  Our responsibilities include delivering exceptional service, using top grade
                  materials, and offering flexible, on-site solutions that minimize disruption to
                  our clients' operations.<br/>
                  We prioritize client satisfaction through reliability, transparency, and
                  consistent communication, ensuring that our services meet their specific
                  needs.<br/>
                  Our company culture is built around integrity, innovation, teamwork, and
                  customer focus. We foster a positive work environment where employees
                  are encouraged to collaborate, continuously improve their skills, and take
                  pride in delivering exceptional service.
                  We believe in going the extra mile for our clients while maintaining a culture
                  of respect, accountability, and commitment to quality. 
                </span>
                <span id='cc2'>
                    <img alt='' src={ceo}/>
                </span>
            </div>
          </div>
        </header>
        <main>
          <section id='section4'>
            <h1>Why Choose Us</h1>
            <div>
              <span>
                <h2>Quality</h2>
                <p>Delivering superior rust protection services using the best materials and techniques available. </p>
              </span>
              <span>
                <h2>Customer Focus</h2>
                <p>Prioritizing client needs by offering flexible, mobile services that minimize downtime and provide maximum convenience.</p>
              </span>
              <span>
                <h2>Integrity</h2>
                <p>Operating with honesty and transparency in all business dealings, building long-term trust with our clients.</p>
              </span>
              <span>
                <h2>Innovation</h2>
                <p>Continuously improving our methods, tools, and services to stay at the forefront of rust protection technology. </p>
              </span>
            </div>
          </section>
          <section id='section2'>
            <h1 style={{color: '#000080'}}>Our Vision</h1>
            <p>
              DESTIVICT SOLUTIONS INC exists to provide top-tier rust protection
              and control services to businesses and individuals, helping them
              preserve the lifespan and value of their vehicles and industrial
              equipment. We aim to reduce the costly impact of rust on fleets and
              machinery by offering convenient, in-store and mobile solutions that
              prioritize efficiency and long-term asset care.
            </p>
          </section>
          <Getsec/>
        </main>
        <Footer/>
    </div>
  )
}

export default About;